@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Roboto-Regular.ttf);
  font-display: swap;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: 'Roboto', sans-serif;
  /* min-height: 100vh; */
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

.header__name {
  margin: 5vh 10vw;
  font-size: 2rem;
}

.about {
  margin: 5vh 10vw;
  font-size: 1.5rem;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .header__name {
    margin: 5vh 10vw;
    font-size: 3rem;
  }

  .about {
    /* font-size: 2rem; */
  }
}
